<template>
    <div>
        <textarea id="editor" name="content" class="content" ></textarea>
    </div>
</template>
<script>
    export default {
        name: 'ue',
        props: {
            value: {
                type:String,
                default:''
            },
            config: {
                type:Object,
                default(){
                    return {}
                }
            }
        },
        data(){
            return {
                editor: null  
            }
        },
        methods:{
            getUEContent () {
                return this.editor.getContent()
            },
            getUETxt () {
                return this.editor.getPlainTxt()
            },
            setUEContent (value){
                return this.editor.setContent(value)
            }     
        },
        mounted () {
            const _this = this
            this.editor = window.UE.getEditor('editor', this.config)

            this.editor.addListener('ready', function () {
                _this.editor.setContent(_this.value)
            })
            // this.editor.addListener('contentChange', function () {
            //     // 获取内容并去掉标签 转换成纯文本
            //     //let html = _this.editor.getContentTxt();
            //     // html = html.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
            //     // 设置字数
            //     //_this.$store.commit('changeTotal',html.length);
            //     // console.log(_this.$store.state.total);
            //     // _this.$parent.$data.textTotal = html.length;
            //     // console.log(_this.$parent.$data.textTotal);                
            // })
            // 解决行高影响的问题 等dom完全渲染出来以后将行高设置
            setTimeout(()=>{
                document.getElementsByClassName('edui-editor')[0].style.lineHeight = 'initial'
            },500)
        },
        destroyed() {
            this.editor.destroy();
        },
    }
</script>
<style lang="less" scoped>

</style>