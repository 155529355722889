<template>
  <div class="album albumvideo">
    <div>
      <p class="type_title">
        <span>视频介绍</span>
      </p>
      <div class="pic_img">
        <div class="pic_img_box">
          <el-upload
            class="avatar-uploader"
            action
            :http-request="upload"
            :on-progress="uploadVideoProcess"
            :before-upload="beforeUploadVideo"
            :show-file-list="false"
          >
            <video
              v-if="videoForm.showVideoPath != '' && !videoFlag"
              v-bind:src="videoForm.showVideoPath"
              class="avatar video-avatar"
              controls="controls"
            >
              您的浏览器不支持视频播放
            </video>
            <i
              v-else-if="videoForm.showVideoPath == '' && !videoFlag"
              class="el-icon-plus avatar-uploader-icon"
            ></i>
            <el-progress
              v-if="videoFlag == true"
              type="circle"
              v-bind:percentage="videoUploadPercent"
              style="margin-top: 7px"
            ></el-progress>
          </el-upload>
        </div>
      </div>
    </div>
    <p class="Upload_pictures">
      <span></span>
      <span>最多可以上传1个视频，建议大小50M，推荐格式mp4</span>
    </p>
  </div>
</template>

<script>
import { uploadAdmin } from "@/api/common";
export default {
  data() {
    return {
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: "",
      //进度条的进度，
      isShowUploadVideo: false,
      //显示上传按钮
      videoForm: {
        showVideoPath: "",
      },
    };
  },
  methods: {
    //上传前回调
    beforeUploadVideo(file) {
      var fileSize = file.size / 1024 / 1024 < 1500;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
          "video/mov",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的视频格式");
        // layer.msg("请上传正确的视频格式");
        return false;
      }
      if (!fileSize) {
        this.$message.error("视频大小不能超过50MB");
        // layer.msg("视频大小不能超过50MB");
        return false;
      }
      this.isShowUploadVideo = false;
    },
    //进度条
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },
    async upload(file) {
      // this.fileList = []
      const form = new FormData();
      form.append("files", file.file);
      const { data: res } = await uploadAdmin(form);
      if (res.code === 10200) {
        (this.videoForm.showVideoPath = res.data.fileLook[0]),
          (this.isShowUploadVideo = true);
        this.videoFlag = false;
        this.videoUploadPercent = 0;
        // this.$emit('updateImg', this.testList)
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-list__item {
  transition: none !important;
}
</style>
